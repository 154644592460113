/* eslint-disable no-console */
import { get, lowerCase } from 'lodash';
import DatadogHandler from 'utils/datadog';
import {
  MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS,
  classOpsService,
} from '../../../utils/msEndpointConstants';
import { retrieveObjectMapper, retrieveSortForMS } from '../../../utils';
import { DEFAULT_ERROR } from '../../../utils/constants';

const {
  GET_EVENTS,
  GET_EVENT_BY_ID,
  CREATE_EVENT,
  CREATE_DRAFT_EVENT,
  GET_EVENT_SCOPES,
} = MS_PHASE_2D_CLASS_OPERATIONS_ENDPOINTS.EVENT;

export const transformEventResponse = res => {
  const data = get(res, 'data', {});
  return {
    totalCount: get(data, 'totalCount', 0),
    data: get(data, 'data', []).map(dt => ({
      ...retrieveObjectMapper(dt, [
        'ID:id',
        'fkCreator:fk_creator',
        'fkApprover:fk_approver',
        'title',
        'description',
        'location',
        'eventImageKey:event_image_key',
        'eventImageKeyPresignedURL:event_image_key_presigned_url',
        'eventType:event_type',
        'from',
        'to',
        'guestCountLimit:guest_count_limit',
        'parentConsentRequired:parent_consent_required',
        'status:status_string',
      ]),
      // eventScopes: {},
      // userByFkCreator: {},
      // creator: {},
    })),
  };
};

export const msFetchEventListing = async (_, reqBody = {}) => {
  const { reqData, dispatchEvents = () => {}, dispatch = () => {} } = reqBody;
  try {
    const reqDatas = reqData;
    const _classOpsService = classOpsService();

    const fullApiUrl = status => {
      const queryParams = {
        page: get(reqDatas, 'pagination.page', 1),
        perPage: get(reqDatas, 'pagination.perPage', 10),
        status,
        // ...retrieveSortForMS(get(reqDatas, 'pagination.sort')),
      };
      return `${GET_EVENTS}?${new URLSearchParams(queryParams).toString()}`;
    };
    const [pendingEvents, upcomingEvents, pastEvents, draftEvents] =
      await Promise.all([
        await _classOpsService.get(fullApiUrl('pending')).catch(() => ({})),
        await _classOpsService.get(fullApiUrl('upcoming')).catch(() => ({})),
        await _classOpsService.get(fullApiUrl('past')).catch(() => ({})),
        await _classOpsService.get(fullApiUrl('draft')).catch(() => ({})),
      ]);

    console.log(
      'pendingEvents',
      pendingEvents,
      'upcomingEvents',
      upcomingEvents,
      'pastEvents',
      pastEvents,
      'draftEvents',
      draftEvents
    );

    const sampleResp = {
      data: {
        data: [1, 2, 3, 4, 5, 6, 7].map(item => ({
          id: item,
          title: `Annual Community Event - (${item})`,
          active: true,
          all_invoice_created: true,
          cancellation_date: '2024-10-01T10:00:00Z',
          child_event_fee: 25,
          created_at: '2024-09-01T08:30:00Z',
          description:
            'Annual community event with various activities for children.',
          event_image_key: 'community_event_2024_image.jpg',
          event_image_key_presigned_url:
            'https://example.com/path/to/event/images/community_event_2024_image.jpg',
          event_logo_key: 'community_event_2024_logo.png',
          event_logo_key_presigned_url:
            'https://example.com/path/to/event/logos/community_event_2024_logo.png',
          event_type: 1,
          fk_approver: 123,
          fk_creator: 456,
          guest_count_limit: 100,
          guest_event_fee: 15,
          guests_allowed: true,
          guests_details_required: true,
          location: 'Community Park, Downtown',
          max_slots: 200,
          parent_consent_required: true,
          payment_required: true,
          publish_date: '2024-09-15T12:00:00Z',
          remarks: 'Please arrive 15 minutes early.',
          rsvp_deadline: '2024-10-10T23:59:59Z',
          status: 1,
          status_string: 'published',
          terms_and_conditions:
            "All participants must adhere to the event's code of conduct.",
          from: '2024-10-15T09:00:00Z',
          to: '2024-10-15T17:00:00Z',
          updated_at: '2024-09-01T08:30:00Z',
        })),
        totalCount: 7,
      },
    };

    const pendingEventResp = transformEventResponse(sampleResp);
    const upcomingEventResp = transformEventResponse(sampleResp);
    const pastEventResp = transformEventResponse(sampleResp);
    const draftEventResp = transformEventResponse(sampleResp);

    const result = {
      getDraftEvents: {
        ...draftEventResp,
      },
      getPastEvents: {
        ...pastEventResp,
      },
      getUpcomingEvents: {
        ...upcomingEventResp,
      },
      getPendingEvents: {
        ...pendingEventResp,
      },
    };

    dispatchEvents(dispatch, false, result);
    return result;
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return dispatchEvents(dispatch, false, null, { error: e.message });
  }
};

export const transformEventByIdResponse = res => {
  const data = get(res, 'data', {});
  return {
    eventById: {
      ID: get(data, 'id'),
      fkCreator: get(data, 'fk_creator'),
      fkApprover: get(data, 'fk_approver'),
      status: get(data, 'status_string'),
      title: get(data, 'title'),
      description: get(data, 'title'),
      eventType: get(data, 'event_type'),
      from: get(data, 'from'),
      to: get(data, 'to'),
      publishDate: get(data, 'publish_date'),
      cancellationDate: get(data, 'cancellation_date'),
      rsvpDeadline: get(data, 'rsvp_deadline'),
      eventImageKey: get(data, 'event_image_key'),
      eventImageKeyPresignedURL: get(data, 'event_image_key_presigned_url'),
      parentConsentRequired: get(data, 'parent_consent_required'),
      termsAndConditions: get(data, 'terms_and_conditions'),
      paymentRequired: get(data, 'payment_required'),
      childEventFee: get(data, 'child_event_fee'),
      guestEventFee: get(data, 'guest_event_fee'),
      guestsAllowed: get(data, 'guests_allowed'),
      guestsDetailsRequired: get(data, 'guests_details_required'),
      guestCountLimit: get(data, 'guest_count_limit'),
      location: get(data, 'location'),
      // eventScopes: {},
      // eventPhotos: {},
      // userByFkCreator: {},
      // creator: {},
    },
  };
};

const transformEventScopeResponse = res => {
  return {
    eventScopes: {
      data: get(res, 'data.data', []).map(eachScopes => ({
        ...retrieveObjectMapper(eachScopes, [
          'fkEvent:fk_event',
          'fkSchool:fk_school',
          'fkCentre:fk_centre',
          'fkClass:fk_class',
          // centre
          // class
          // school
        ]),
      })),
    },
  };
};

export const msFetchEventByID = async (_, reqBody = {}) => {
  const {
    reqData,
    dispatchfetchEventByID = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    const _classOpsService = classOpsService();
    const resp = await _classOpsService.get(
      GET_EVENT_BY_ID(get(reqData, 'IDEvent'))
    );
    console.log('resp', resp);
    const sampleResp = {
      data: {
        id: 789,
        title: 'Annual Community Event 2024',
        active: true,
        all_invoice_created: true,
        cancellation_date: '2024-10-01T10:00:00Z',
        child_event_fee: 25,
        created_at: '2024-09-01T08:30:00Z',
        description:
          'Annual community event with various activities for children.',
        event_image_key: 'community_event_2024_image.jpg',
        event_image_key_presigned_url:
          'https://example.com/path/to/event/images/community_event_2024_image.jpg',
        event_logo_key: 'community_event_2024_logo.png',
        event_logo_key_presigned_url:
          'https://example.com/path/to/event/logos/community_event_2024_logo.png',
        event_type: 1,
        fk_approver: 123,
        fk_creator: 456,
        guest_count_limit: 100,
        guest_event_fee: 15,
        guests_allowed: true,
        guests_details_required: true,
        location: 'Community Park, Downtown',
        max_slots: 200,
        parent_consent_required: true,
        payment_required: true,
        publish_date: '2024-09-15T12:00:00Z',
        remarks: 'Please arrive 15 minutes early.',
        rsvp_deadline: '2024-10-10T23:59:59Z',
        status: 3,
        status_string: 'published',
        terms_and_conditions:
          "All participants must adhere to the event's code of conduct.",
        from: '2024-10-15T09:00:00Z',
        to: '2024-10-15T17:00:00Z',
        updated_at: '2024-09-01T08:30:00Z',
      },
    };
    const result = transformEventByIdResponse(sampleResp);
    const eventId = get(result, 'eventById.ID');

    const [eventScopes] = await Promise.all([
      _classOpsService.get(GET_EVENT_SCOPES(eventId)).catch(() => ({})),
    ]);

    const eventScopeResp = transformEventScopeResponse(eventScopes);
    console.log('eventScopeResp', eventScopeResp);

    dispatchfetchEventByID(dispatch, false, result);
    return result;
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return dispatchfetchEventByID(dispatch, false, null, { error: e.message });
  }
};

export const msCreateNewEvent = async (_, reqBody = {}) => {
  try {
    // {
    //     "event": {
    //       "title": "test",
    //       "description": "test",
    //       "eventType": 607,
    //       "from": "2024-09-11 12:03:05",
    //       "to": "2024-09-11 13:03:05",
    //       "cancellationDate": "2024-09-11 00:00:00",
    //       "rsvpDeadline": "2024-09-10 00:00:00",
    //       "eventImageKey": "",
    //       "eventLogoKey": "",
    //       "location": "",
    //       "parentConsentRequired": false,
    //       "termsAndConditions": "I hereby consent to my child's participation in the event / field trip. I understand that the school will take the necessary measures to ensure the well-being of my child and I will not hold the school responsible for any mishap or accident that may occur during the event / field trip.\n\nI understand that:\n\n 1. The Child Development Account (CDA) can only be used for a child's approved educational expenses such as field trips.\n 2. Unapproved educational expenses such as child's participation in events, parent's participation in field trips, etc must be paid via Non-CDA GIRO or PayNow.\n 3. Upon registration deadline and given consent, bookings will be made. The centre is unable to provide a refund should my child be absent on the day of the event / field trip.",
    //       "guestsAllowed": false,
    //       "guestCountLimit": null,
    //       "guestsDetailsRequired": false,
    //       "paymentRequired": false,
    //       "childEventFee": null,
    //       "guestEventFee": null
    //     },
    // missing
    //     "IDSchool": 2,
    //     "IDCentre": 15,
    //     "IDClasses": [
    //       1193
    //     ]
    //   }
    const body = {
      // all_invoice_created: true,
      cancellation_date: get(reqBody, 'event.cancellationDate'),
      child_event_fee: get(reqBody, 'event.childEventFee'),
      description: get(reqBody, 'event.description'),
      event_image_key: get(reqBody, 'event.eventImageKey'),
      event_logo_key: get(reqBody, 'event.eventLogoKey'),
      event_type: get(reqBody, 'event.eventType'),
      // fk_approver: 0,
      // fk_creator: 0,
      from: get(reqBody, 'event.from'),
      guest_count_limit: get(reqBody, 'event.guestCountLimit'),
      guest_event_fee: get(reqBody, 'event.guestEventFee'),
      guests_allowed: get(reqBody, 'event.guestsAllowed'),
      guests_details_required: get(reqBody, 'event.guestsDetailsRequired'),
      location: get(reqBody, 'event.location'),
      // max_slots: 0,
      parent_consent_required: get(reqBody, 'event.parentConsentRequired'),
      payment_required: get(reqBody, 'event.paymentRequired'),
      // publish_date:,
      // remarks:,
      rsvp_deadline: get(reqBody, 'event.rsvpDeadline'),
      // status: 0,
      terms_and_conditions: get(reqBody, 'event.termsAndConditions'),
      title: get(reqBody, 'event.title'),
      to: get(reqBody, 'event.to'),
    };
    const res = await classOpsService().post(CREATE_EVENT, body);

    return {
      data: {
        submitEvent: {
          ID: get(res, 'data.id'),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msSaveNewDraftEvent = async (_, reqBody = {}) => {
  try {
    // {
    //     "event": {
    //       "title": "123",
    //       "description": "123",
    //       "eventType": 607,
    //       "from": "2024-09-11 12:14:49",
    //       "to": "2024-09-11 13:14:49",
    //       "cancellationDate": "2024-09-11 00:00:00",
    //       "rsvpDeadline": "2024-09-10 00:00:00",
    //       "eventImageKey": "",
    //       "eventLogoKey": "",
    //       "location": "",
    //       "parentConsentRequired": false,
    //       "termsAndConditions": "I hereby consent to my child's participation in the event / field trip. I understand that the school will take the necessary measures to ensure the well-being of my child and I will not hold the school responsible for any mishap or accident that may occur during the event / field trip.\n\nI understand that:\n\n 1. The Child Development Account (CDA) can only be used for a child's approved educational expenses such as field trips.\n 2. Unapproved educational expenses such as child's participation in events, parent's participation in field trips, etc must be paid via Non-CDA GIRO or PayNow.\n 3. Upon registration deadline and given consent, bookings will be made. The centre is unable to provide a refund should my child be absent on the day of the event / field trip.",
    //       "guestsAllowed": false,
    //       "guestCountLimit": null,
    //       "guestsDetailsRequired": false,
    //       "paymentRequired": false,
    //       "childEventFee": null,
    //       "guestEventFee": null
    //     },
    // missing
    //     "IDSchool": 2,
    //     "IDCentre": 15,
    //     "IDClasses": [
    //       1193
    //     ]
    //   }
    const body = {
      // all_invoice_created: true,
      cancellation_date: get(reqBody, 'event.cancellationDate'),
      child_event_fee: get(reqBody, 'event.childEventFee'),
      description: get(reqBody, 'event.description'),
      event_image_key: get(reqBody, 'event.eventImageKey'),
      event_logo_key: get(reqBody, 'event.eventLogoKey'),
      event_type: get(reqBody, 'event.eventType'),
      // fk_approver: 0,
      // fk_creator: 0,
      from: get(reqBody, 'event.from'),
      guest_count_limit: get(reqBody, 'event.guestCountLimit'),
      guest_event_fee: get(reqBody, 'event.guestEventFee'),
      guests_allowed: get(reqBody, 'event.guestsAllowed'),
      guests_details_required: get(reqBody, 'event.guestsDetailsRequired'),
      location: get(reqBody, 'event.location'),
      // max_slots: 0,
      parent_consent_required: get(reqBody, 'event.parentConsentRequired'),
      payment_required: get(reqBody, 'event.paymentRequired'),
      // publish_date:,
      // remarks:,
      rsvp_deadline: get(reqBody, 'event.rsvpDeadline'),
      // status: 0,
      terms_and_conditions: get(reqBody, 'event.termsAndConditions'),
      title: get(reqBody, 'event.title'),
      to: get(reqBody, 'event.to'),
    };

    const res = await classOpsService().post(CREATE_DRAFT_EVENT, body);
    return {
      data: {
        createOrSaveEventDraft: {
          ID: get(res, 'data.id'),
        },
      },
    };
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(ex, {}, 'error');
    return {
      errors: [
        get(ex, 'response.data.error') || get(ex, 'message', DEFAULT_ERROR),
      ],
    };
  }
};

export const msFetchCurrentEventCategory = async (_, reqBody = {}) => {
  const {
    reqData,
    dispatchCurrentEventCategory = () => {},
    dispatch = () => {},
  } = reqBody;
  try {
    const { reqParams, type } = reqData;
    const eventType = lowerCase(type);
    const _classOpsService = classOpsService();

    const fullApiUrl = status => {
      const queryParams = {
        page: get(reqParams, 'pagination.page', 1),
        perPage: get(reqParams, 'pagination.perPage', 10),
        status,
        ...retrieveSortForMS(get(reqParams, 'pagination.sort')),
      };
      return `${GET_EVENTS}?${new URLSearchParams(queryParams).toString()}`;
    };
    const eventResp = await _classOpsService
      .get(fullApiUrl(eventType))
      .catch(() => ({}));

    console.log('eventResp', eventResp);
    const sampleResp = {
      data: {
        data: [1, 2, 3, 4, 5, 6, 7].map(item => ({
          id: item,
          title: `Annual Community Event - (${item})`,
          active: true,
          all_invoice_created: true,
          cancellation_date: '2024-10-01T10:00:00Z',
          child_event_fee: 25,
          created_at: '2024-09-01T08:30:00Z',
          description:
            'Annual community event with various activities for children.',
          event_image_key: 'community_event_2024_image.jpg',
          event_image_key_presigned_url:
            'https://example.com/path/to/event/images/community_event_2024_image.jpg',
          event_logo_key: 'community_event_2024_logo.png',
          event_logo_key_presigned_url:
            'https://example.com/path/to/event/logos/community_event_2024_logo.png',
          event_type: 1,
          fk_approver: 123,
          fk_creator: 456,
          guest_count_limit: 100,
          guest_event_fee: 15,
          guests_allowed: true,
          guests_details_required: true,
          location: 'Community Park, Downtown',
          max_slots: 200,
          parent_consent_required: true,
          payment_required: true,
          publish_date: '2024-09-15T12:00:00Z',
          remarks: 'Please arrive 15 minutes early.',
          rsvp_deadline: '2024-10-10T23:59:59Z',
          status: 1,
          status_string: 'published',
          terms_and_conditions:
            "All participants must adhere to the event's code of conduct.",
          from: '2024-10-15T09:00:00Z',
          to: '2024-10-15T17:00:00Z',
          updated_at: '2024-09-01T08:30:00Z',
        })),
        totalCount: 7,
      },
    };
    const transformedEventCategoryResp = transformEventResponse(sampleResp);

    dispatchCurrentEventCategory(dispatch, false, transformedEventCategoryResp);
    return transformedEventCategoryResp;
  } catch (e) {
    DatadogHandler.addError(e);
    DatadogHandler.sendLog(e, {}, 'error');
    return dispatchCurrentEventCategory(dispatch, false, null, {
      error: e.message,
    });
  }
};

export const msUpdateEventDetails = async (_, reqBody = {}) => {
  console.log('reqBody', reqBody);
};

export default {
  msFetchEventListing,
  msFetchEventByID,
  msCreateNewEvent,
  msSaveNewDraftEvent,
  msFetchCurrentEventCategory,
  msUpdateEventDetails,
  transformEventResponse,
  transformEventScopeResponse,
};
